.popup {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #fff;
    padding: 15px 20px;
    border: 1px solid #ddd;
    border-radius: 0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100%;
  }
  
  .message {
    margin: 0 0 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
  
  .button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px auto;
    width: 200px;
    display: block;
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
  }
  